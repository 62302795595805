import React from "react"
import { Button, Form, FormLayout, TextField } from "@shopify/polaris"

export const InstallForm = props => {
  const { shop, hasShop, handleChange, handleSubmit, isLoading } = props

  return (
    <Form method="POST" onSubmit={handleSubmit}>
      <FormLayout>
        <TextField
          id="shop"
          name="shop"
          value={shop}
          onChange={handleChange}
          label="Enter your shop name"
          type="text"
          placeholder="example"
          prefix="https://"
          suffix=".myshopify.com"
          error={!hasShop && "Shop domain is required"}
          required=""
          autoComplete="off"
        />
        <Button primary submit loading={isLoading}>
          Install
        </Button>
      </FormLayout>
    </Form>
  )
}
