import React from "react"
import { Banner, Card, DisplayText, Layout } from "@shopify/polaris"

import { InstallForm } from "./InstallForm"

export const Install = props => {
  const { title, error } = props

  return (
    <div
      style={{
        maxWidth: "500px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px",
      }}
    >
      <Layout>
        <Layout.Section>
          <br />
          <br />
          <br />
          {error && (
            <>
              <Banner title="There was an error." status="critical">
                {error}
              </Banner>
              <br />
            </>
          )}
          <Card sectioned>
            <DisplayText size="large">{title}</DisplayText>
            <br />
            <InstallForm {...props} />
          </Card>
        </Layout.Section>
      </Layout>
    </div>
  )
}
