import React, { useState, useCallback, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { AppProvider, Page } from "@shopify/polaris"
import Helmet from "react-helmet"
import axios from "axios"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

import { Install } from "components/Install/Install"

const query = graphql`
  query InstalQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const InstallPage = () => {
  const [shop, setShop] = useState(null)
  const [hasShop, setHasShop] = useState(true)
  const [hasError, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    const shop = urlParams.get("shop")

    if (shop) {
      setShop(shop.replace(".myshopify.com", ""))
    }
  }, [])

  const handleChange = useCallback(value => setShop(value), [setShop])

  const handleSubmit = useCallback(() => {
    setLoading(true)
    setHasShop(true)
    setError(false)

    if (shop) {
      axios
        .post("/auth", {
          shop,
        })
        .then(response => {
          try {
            ReactifyApp.Auth.processAuth(response)
            return
          } catch (error) {
            throw new Error(error)
          }
        })
        .catch(error => {
          setError(error)
          setLoading(false)
        })
    } else {
      setHasShop(false)
      setError("Missing shop parameter.")
      setLoading(false)
    }
  }, [shop])

  return (
    <AppProvider>
      <StaticQuery
        query={query}
        render={data => (
          <Page>
            <Helmet
              htmlAttributes={{
                lang: "en",
              }}
              title={"Install"}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            />
            <Install
              title={data.site.siteMetadata.title}
              shop={shop}
              hasShop={hasShop}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              error={hasError}
              isLoading={isLoading}
            />
          </Page>
        )}
      />
    </AppProvider>
  )
}

export default InstallPage
